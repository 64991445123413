/*
 * ====================================================================
 * Grow Template
 * @package  Grow Template
 * @author   GrowGroup.Inc <info@grow-group.jp>
 * @license  MIT Licence
 * ====================================================================
 */

(function ($) {
    'use strict';

    var growTemplate = function () {
    };

    /**
     * slidebars
     */
    growTemplate.prototype.slidebars = function () {
        $.slidebars();
    }

    /**
     * owl.carousel
     */
    growTemplate.prototype.owlCarousel = function () {

      $('.c-main-visual .owl-carousel').owlCarousel({
          items: 1,
          autoWidth: false,
          loop: true,
          center: true,
          nav: false,
          animateIn: "fadeIn",
          animateOut: "fadeOut",
          autoplayHoverPause: false,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplaySpeed: 700
        });
    }

    /**
     * facebook
     */
    growTemplate.prototype.faceBook = function () {
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "//connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v2.6";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    
    /**
     * スマホ閲覧時の予約ボタン
     */
    growTemplate.prototype.scrollDownButton = function () {
        var $win = $(window),
            $winWidth = $(window).width();

        if (screen.width < 640) {
            function animateReservedButton() {
                var pageY = ( typeof window.pageYOffset !== "undefined" ) ? window.pageYOffset : document.body.scrollTop;
                if (window.pageYOffset > 100) {
                    $('.l-side-fixed-btn').fadeIn( 'fast' );
                } else {
                    $('.l-side-fixed-btn').fadeOut( 'fast' );
                }
                window.requestAnimationFrame( animateReservedButton );
            }

            window.requestAnimationFrame( animateReservedButton );
        }

        if (screen.width > 640) {
            function animateReservedBanner() {
                var pageY = ( typeof window.pageYOffset !== "undefined" ) ? window.pageYOffset : document.body.scrollTop;
                if (window.pageYOffset > 330) {
                    $('.l-side-fixed').fadeIn( 'fast' );
                } else {
                    $('.l-side-fixed').fadeOut( 'fast' );
                }
                window.requestAnimationFrame( animateReservedBanner );
            }

            window.requestAnimationFrame( animateReservedBanner );
        }
    }

    /**
     * ページトップヘ戻る
     */
    growTemplate.prototype.pageTop = function () {
        $('.js-pagetop').on('click', function (e) {
            e.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 500);
        });
    }

    /**
     * チェックボックス（個人情報承諾）のチェック判定
     */
    growTemplate.prototype.checkBoxPrivacy = function () {
      $( function () {
        var $p_check = $('#privacy_check'),
            $p_check_confirm = $('input[name=submitBack]'),
            $submit = $('input[type=submit]');


          // 個人情報保護方針がなければスルー
          if ( $p_check_confirm.length != 0 ) {
              return false;
          }
          // まず押せなくする
            $submit.prop('disabled', true);

          // 押せない場合は透明に
            if ( $submit.is(':disabled') ) {
                $submit.css("opacity", '0.3');
            }

          // クリックしたら切り替わる
            $p_check.on( 'click', function () {
              if ( $submit.is(':disabled') ) {
                $submit.css("opacity", '1.0');
                $submit.prop('disabled', false);
              } else {
                $submit.css("opacity", '0.3');
                $submit.prop('disabled', true);
              }
            });
      });
    }

    /**
     * グローバルナビ　カレント表記
     */
     growTemplate.prototype.navCarrent = function () {
      $(function() {

        var url = window.location.pathname.split('/'),
            targetElement = $('.l-global-navigation > .l-container > ul > li'),
            targetElementSide = $('.l-aside__menu > ul > li'),
            currentLink = targetElement.find( 'a' ),
            currentLinkSide = targetElementSide.find( 'a' );


            currentLink.each( function () {
                if ( $(this).attr( 'href' ).split('/')[1] === url[1] ) {
                  $(this).parent('li').addClass('is-current');
                }
            });

            currentLinkSide.each( function () {
                if ( $(this).attr( 'href' ).split('/')[3] === url[1] && $(this).attr( 'href' ).split('/')[4] === url[2] ) {
                  $(this).parent('li').addClass('is-current');
                }
            });
      });
    }

    /**
     * 受講クラス アンカーリンク
     */
    growTemplate.prototype.BlankLink = function () {
        if ( $('#student').length === 0 ) {
            return false;
        }
        var headerHight = 100,
            n = window.location.href.slice( window.location.href.indexOf( '?' ) + 4 ),
            p = $("#student").offset().top-headerHight,
            param = window.location.search; //?以降のクエリを取得

        if ( 0 < window.location.href.indexOf( '?' ) ) {
            $('html,body').delay( 400 ).animate({ scrollTop: p }, 'slow');
        }
    }

    /**
     * 動画 アンカーリンク
     */
    growTemplate.prototype.BlankLinkMovie = function () {
        if ( $('#movie').length === 0 ) {
            return false;
        }
        var headerHight = 100,
            n = window.location.href.slice( window.location.href.indexOf( '?' ) + 4 ),
            p = $("#movie").offset().top-headerHight,
            param = window.location.search; //?以降のクエリを取得

        if ( 0 < window.location.href.indexOf( '?' ) ) {
            $('html,body').delay( 400 ).animate({ scrollTop: p }, 'slow');
        }
    }

    $(function () {
        var app = new growTemplate();
        app.slidebars();
        app.owlCarousel();
        app.faceBook();
        app.scrollDownButton();
        app.checkBoxPrivacy();
        app.pageTop();
        app.navCarrent();
        app.BlankLink();
        app.BlankLinkMovie();
    });

})(jQuery);
